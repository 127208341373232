;(function ($, window, undefined) {

  var _Common
  // var _Common = null
  var Home = {
    _Common     : null,
    $window     : $(window),
    $groups     : null,
    groups_index: 0,
    groups_max  : 0,
    target_group: null,
    scroll_top  : 0,
    scroll_btm  : 0,

    /* ===============================================
     * ページ読み込み後、_Commonから一度だけ呼び出されます
     * =============================================== */
    setupOnce: function () {
      _Common.trace('Home -> setupOnce')

      /**
       * スクロールボタンクリックでABOUTへ移動
       */
      $('.main__scroll').on('click', function () {
        autoScroll('#about', 0, 500, 'easeInOutQuart')
      })

      /**
       * フッターメニュークリックでアンカースクロール
       */
      $('.footer-menu__item a').on('click', function (e) {
        e.preventDefault()

        var target = $(this).attr('href')

        autoScroll(target, 0, 500, 'easeInOutQuart')
      })

      /**
       * ページ表示時、スクロール位置がトップの場合はイントロ再生
       *
       * @type {number}
       */
      var scrollTop = document.body.scrollTop || document.documentElement.scrollTop
      if (scrollTop > 10) {
        $('.main-cover').hide()
        $('body').removeClass('is-overflow-hidden')
      }
      else {
        this.intro()
      }

      /**
       * スクロールでフェードイン
       */
      this.setScrollEvent()

      /**
       * メールフォーム設定
       */
      this.MailForm.init()

    },

    /* ===============================================
     * イントロ
     * =============================================== */
    intro: function () {

      /**
       * スクロールボタンの表示
       */
      TweenMax.fromTo('.main__scroll', .4, { opacity: 0, y: -10 }, { opacity: 1, y: 0, delay: 3.3 })

      /**
       * ロゴ表示 > 反転してフェードイン
       */
      TweenMax.fromTo('.main-cover__logo', 1.5, { opacity: 0 }, {
        opacity   : 1, ease: Power2.easeOut, delay: .2,
        onComplete: function () {
          TweenMax.to('.main-cover', .8, {
            opacity   : 0,
            delay     : 1,
            ease      : Power2.easeInOut,
            onComplete: function () {
              $('.main-cover').hide()
              $('body').removeClass('is-overflow-hidden')
            }
          })

        }
      })

    },

    /* ===============================================
     * スクロールイベント登録
     * =============================================== */
    setScrollEvent: function () {

      /* -----------------------------------------------
       * 画像のスクロールフェードイン
       * ----------------------------------------------- */
      Home.$groups      = $('.js-scroll-effect')
      Home.groups_index = 0
      Home.groups_max   = Home.$groups.length
      Home.target_group = $(Home.$groups[0])
      Home.scroll_top   = 0
      Home.scroll_btm   = Home.scroll_top + _Common.Window.height

      Home.$groups.each(function (i, ele) {
        var $ele = $(ele)
        $ele.addClass('effect-index' + String(i))
        if ($ele.offset().top > Home.scroll_btm) {
          $ele.css({ opacity: 0, position: 'relative', top: 30 })
        }
        else {
          Home.groups_index++
        }

      })

      if (Home.groups_index < Home.groups_max) {
        Home.target_group = $(Home.$groups[Home.groups_index])
        Home.$window.on('scroll', Home.onScroll).trigger('scroll')
      }

    },

    /* -----------------------------------------------
     * スクロールイベント
     * ----------------------------------------------- */
    onScroll: function (e) {

      Home.scroll_top = document.body.scrollTop || document.documentElement.scrollTop
      Home.scroll_btm = Home.scroll_top + _Common.Window.height

      if (Home.scroll_btm - _Common.Window.height * 0.3 > Home.target_group.offset().top) {

        Home.fadeIn(Home.target_group)
        Home.groups_index++

        if (Home.groups_index < Home.groups_max) {
          Home.target_group = $(Home.$groups[Home.groups_index])
        }
        else {
          Home.$window.off('scroll', Home.onScroll)
        }
      }
    },

    fadeIn: function (opt_target) {
      TweenMax.to(opt_target, .6, {
        opacity   : 1, top: 0, ease: Power4.easeOut,
        onComplete: function () {
          $(opt_target).css({
            opacity: '',
            top    : ''
          })
          opt_target = null
        }
      })

    },

    MailForm: {
      init: function(){
        var _this = this;

        $(".contact__submitBtn").on("click", _this.check);
      },
      check: function(e){
        e.preventDefault();
        var _this = this;

        if($(".contact__submitBtn").css("pointer-events") == "none") return;

        if( $("#name").val() && $("#email").val() && $("#message").val() ){

          if(!$("#email").val().match("^[0-9A-Za-z._\-]+@[0-9A-Za-z.\-]+$")){
            $('#error').html('メールアドレス形式で記入して下さい');
            return false;
          }

          Home.MailForm.send();
          $(".contact__submitBtn").css({pointerEvents: "none"});

        } else {
          Home.MailForm.alert();
        }

      },
      send: function(){
        var _this = this;
        var data = $("#form").serialize(); //送信されたデータ
        $('#error').html('');
        // $('#form').html('<img src="../lib/loader2.gif" />'); //ローディング画像を表示
        $.ajax({
          type: "POST",
          url: "mail.php", //PHPを呼び出す
          data: data, //記入されたデータを渡す
          success: function(xml){
            _this.complete();
          },
          error: function(){
            _this.sendError();
          }
        });
      },
      complete: function(){
        $('.contact__complete').addClass("is-active");

        $("#name").val("");
        $("#email").val("");
        $("#message").val("");

        setTimeout(function(){
          $(".contact__submitBtn").css({pointerEvents: ""});
          $('.contact__complete').removeClass("is-active");
        }, 3000);

      },
      alert: function(){
        $('#error').html('未記入または形式の違う項目が有ります');
      },
      sendError: function(){
        $('#error').html('送信に失敗しました');
      }
    },

  }

  _Common = Common
  Common.setPage(Home)

})(jQuery, window)